<template>
    <div id="course-management">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>课件资源管理</h1>
            <div class="search-bar">
                <div class="lf">
                    <div class="course-entrybox">
<!--                        <i class="icon-search"></i>-->
<!--                        <span>课件</span>-->
                        <input type="text" v-model="coursewareName" placeholder="请输入课件名称">
                    </div>
                    <div class="courseware-entrybox">
                        <input type="text" v-model="courseName" placeholder="请输入所属课程名称">
                    </div>
                    <div class="course-status-options">
                        <el-select v-model="courseStatus">
                            <el-option
                                v-for="item in status"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="calendar">
                        <el-date-picker
                            popper-class="popDateBox"
                            v-model="searchDate"
                            type="daterange"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="btn-search" @click="search">查询</div>
                </div>
                <div class="rf" @click="release">上传课件资源</div>
            </div>
            <div class="table">
                <el-table
                        :data="coursewareList"
                        style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="ID"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="名称">
                    </el-table-column>
                    <el-table-column
                        prop="courseName"
                        align="center"
                        label="所属课程">
                        <template slot-scope="scope" class="table-status">
                            <span :style="{color: scope.row.courseName ? '#666' : '#999'}">{{ scope.row.courseName ? scope.row.courseName : '未分配' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        align="center"
                        width="120"
                        label="课件状态">
                        <template slot-scope="scope" class="table-status">
                            <span :style="{'color': scope.row.status === 1 ? '#1DC084' : scope.row.status === 2 ? '#DC000C' : '#666666'}" :class="scope.row.status === 2 ? 'off-shelf' : ''">{{ scope.row.status === 0 ? '审核中' : scope.row.status === 1 ? '上架中' : "已下架" }}</span>
                            <div class="off-shelf-reason" v-if="scope.row.status === 2">{{ scope.row.remark }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="createTime"
                            align="center"
                            label="新增时间">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | formatDate }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="sort"
                            label="排序"
                            width="80">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        width="220"
                        label="操作"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEdit(scope.row)"
                                v-if="scope.row.status !== 1"
                            >编辑</el-button>
                            <el-button
                                    size="mini"
                                    @click="handleDetail(scope.row)"
                            >详情</el-button>
                            <el-button v-if="scope.row.status !== 1" size="mini" @click="removeCourseware(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <listH5Component
                    :list="coursewareList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.name}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.createTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span :style="{'color': rowData.prop.status === 1 ? '#1DC084' : rowData.prop.status === 2 ? '#DC000C' : '#666666','text-decoration':rowData.prop.status === 2 ? 'underline' : 'none'}" :class="rowData.prop.status === 2 ? 'off-shelf' : ''">{{ rowData.prop.status === 0 ? '审核中' : rowData.prop.status === 1 ? '上架中' : "已下架" }}
                        </span>    
                    </template>
                    <template v-slot:btn="rowData">
                        <span
                            @click="handleEdit(rowData.prop)"
                            v-if="rowData.prop.status !== 1"
                        >
                            编辑
                        </span>
                        <span
                            @click="handleDetail(rowData.prop)"
                        >
                            详情
                        </span>
                        <span 
                            v-if="rowData.prop.status !== 1" 
                            @click="removeCourseware(rowData.prop.id)"
                        >
                            删除
                        </span>
                    </template>
                </listH5Component>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="changeCoursewareList"
                        :current-page.sync="pageCurrent"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                :title="editingType === editingButtons[1] ? '课件资源详情' : editing ? '编辑课件资源' : '新增课件资源'"
                width="35%"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                class="dialog"
                @close="dlOnClosedClearData"
            >
                <!-- 编辑 -->
                <el-form :model="form" v-if="editingType === editingButtons[0]">
                    <el-form-item label="名称" :label-width="formLabelWidth">
                        <el-input v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="文档描述" :label-width="formLabelWidth">
                        <el-input type="textarea" v-model="form.description"></el-input>
                    </el-form-item>
                    <el-form-item label="课件上传" :label-width="formLabelWidth">
                        <el-upload
                                class="upload-courseware"
                                action=""
                                :on-change="handleCoursewareChange"
                                :on-remove="handleCoursewareRemove"
                                :file-list="form.textFileList"
                                accept="image/*,.pdf,.doc,.docx,.ppt,.pptx"
                                :auto-upload="false">
                            <template>
                                <el-button slot="trigger" type="primary" size="small">本地上传</el-button>
                                <!--<el-button v-if="form.textFileList.length > 0"
                                           type="warning" size="small"
                                           @click="downloadPreview('text')"
                                           style="margin-left: 10px;">下载预览</el-button>-->
                            </template>
                            <div slot="tip" class="el-upload__tip" style="color: red;">仅支持pdf、word、ppt及图片格式文件</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="视频上传" :label-width="formLabelWidth">
                        <el-upload
                                class="upload-video"
                                action=""
                                :on-change="handleVideoChange"
                                :on-remove="handleVideoRemove"
                                :file-list="form.videoFileList"
                                accept=".mp4"
                                :auto-upload="false">
                            <template>
                                <el-button slot="trigger" type="primary" size="small">本地上传</el-button>
                                <!--<el-button v-if="form.videoFileList.length > 0"
                                           type="warning" size="small"
                                           @click="downloadPreview('video')"
                                           style="margin-left: 10px;">下载预览</el-button>-->
                            </template>
                            <div slot="tip" class="el-upload__tip" style="color: red;line-height: 150%;">附件视频仅支持MP4格式，大小200M以内，建议使用短视频。</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="课件排序" :label-width="formLabelWidth">
                        <el-input v-model="form.sort" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" v-if="editingType === editingButtons[0]">
                    <el-button type="primary" @click="saveCourseware">{{ editing ? '保存' : '新增' }}</el-button>
                </div>
                
                <!-- 详情 -->
                <div class="course-detail" v-if="editingType === editingButtons[1]">
                    <ul>
                        <li>
                            <label>名称</label>
                            <div>{{ form.name }}</div>
                        </li>
                        <li>
                            <label>课程描述</label>
                            <div>{{ form.description }}</div>
                        </li>
                        <li>
                            <label>课件上传</label>
                            <div>
                                <span>{{ form.textName }}</span>
                                <span class="btn-preview" @click="filePreview('text')">下载</span>
                            </div>
                        </li>
                        <li>
                            <label>视频上传</label>
                            <div>
                                <span>{{ form.videoName }}</span>
                                <span class="btn-preview" @click="filePreview('video')">下载</span>
                            </div>
                        </li>
                        <li>
                            <label>课程排序</label>
                            <div>{{ form.sort }}</div>
                        </li>
                        <li id = "js_remark" v-if = "form.remark != ''">
                            <label>备注</label>
                            <div>{{ form.remark }}</div>
                        </li>
                    </ul>
                </div>
                
                <!-- 上传进度遮罩 -->
                <el-dialog
                    :visible.sync="uploadDialogVisible"
                    class="upload-dialog"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :fullscreen="true"
                    append-to-body>
                    <div class="sk-chase">
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                        <div class="sk-chase-dot"></div>
                    </div>
                    <div class="upload-dialog-text">正在上传视频...</div>
                </el-dialog>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import {urls} from '@/lib/common'
    import man from "../../plugins/man";

    import listH5Component from './component/listH5Component'

    export default {
        name: "CoursewareManagement",
        components: {
            LeftbarComponent,
            TopbarComponent,
            listH5Component
        },
        data() {
            return {
                page: 1,
                total: 100,
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '课件资源管理',
                        disabled: true,
                        href: '/coursewareManagement',
                    }
                ],
                workList: [],
                coursewareName: '',
                courseName: '',
                searchDate: '',
                pageCurrent: 1,
                pageSize: 10,
                dialogVisible: false,
                uploadDialogVisible: false,
                editingButtons: ['EDIT', 'DETAIL'],
                editing: false,
                editingType: '',
                formLabelWidth: '120px',
                form: {
                    id: '',
                    name: '',
                    description: '',
                    textFileList: [],
                    videoFileList: [],
                    sort: 99
                },
                status: [
                    {
                        label: '课件状态',
                        value: ''
                    }, {
                        label: '上架中',
                        value: 1
                    }, {
                        label: '审核中',
                        value: 0
                    }, {
                        label: '已下架',
                        value: 2
                    }
                ],
                courseStatus: '',
                options: [
                    {
                        value: '',
                        label: '选择'
                    }, {
                        value: 'file',
                        label: '文档'
                    }, {
                        value: 'video',
                        label: '视频'
                    }, {
                        value: 'audio',
                        label: '音频'
                    }
                ],
                courseware: '',
                coursewareType: '',
                coursewareList: [],
                courseIndex: null,
                selectedList: [],
                coursewarePagesize: 5, // 绑定课程列表显示列表长度
                coursewarePage: 0, // 绑定课程列表页码
                coursewareTotal: 50, // 绑定课程列表总数
                students: [],

            }
        },
        created() {
            this.getCoursewareList();
        },
        methods: {
            getCoursewareList() {
                const params = {
                    pageCurrent: this.page,
                    pageSize: this.pageSize
                }
                if (this.coursewareName) {
                    params.name = this.coursewareName
                }
                if (this.courseName) {
                    params.courseName = this.courseName
                }
                if (this.courseStatus !== '' && this.courseStatus !== undefined) {
                    params.status = this.courseStatus
                }
                if (this.searchDate) {
                    const start = this.searchDate[0];
                    const end = this.searchDate[1];
                    const startDate = `${this.dateFormat(start)} 00:00:00`;
                    const endDate = `${this.dateFormat(end)} 23:59:59`;
                    params.startDate = startDate;
                    params.endDate = endDate;
                }
                // console.log(params);
                this.$http.get(urls.coursewareList, {params}).then((res) => {
                    // console.log(res.data);
                    if (res.data.status === 200) {
                        this.coursewareList = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            changeCoursewareList(page) {
                this.page = page;
                this.getCoursewareList();
            },
            saveCourseware() {
                // console.log(this.form);return;
                if (!this.form.name) {
                    this.$message.warning("请输入课件名称")
                    return false
                }
                if (!this.form.description) {
                    this.$message.warning("请输入文档描述")
                    return false
                }
                if (!this.form.textName || !this.form.textUrl) {
                    this.$message.warning("请上传课件")
                    return false
                }
                if (!this.form.videoName || !this.form.videoUrl) {
                    this.$message.warning("请上传视频")
                    return false
                }
                // delete this.form.textFileList;
                // delete this.form.videoFileList;
                console.log('params', this.form);
                this.$http.post(this.editing ? urls.coursewareEdit : urls.coursewareSave, this.form).then(res => {
                    // console.log(res)
                    if (res.data.status === 200) {
                        this.dialogVisible = false;
                        this.$message.success("课件已添加!~");
                        this.getCoursewareList();
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            dateFormat(date) {
                const y = date.getFullYear();
                const m = date.getMonth() + 1;
                const d = date.getDate();
                const month = m >= 10 ? m : '0' + m;
                const day = d >= 10 ? d : '0' + d;
                return `${y}-${month}-${day}`
            },
            search() { // 查询
                // console.log('search')
                this.page = 1;
                this.getCoursewareList()
            },
            handleEdit(row) {
                let that = this;

                that.$http.get(`${urls.coursewareFind}/${row.id}`).then((res) => {
                    // console.log(res.data.data);
                    if(res.data.status === 200) {
                        that.form = {
                            ...res.data.data,
                            textFileList: [{name: res.data.data.textName, url: res.data.data.textUrl}],
                            videoFileList: [{name: res.data.data.videoName, url: res.data.data.videoUrl}],
                        };
                        that.$nextTick(function() {
                            that.editingType = that.editingButtons[0];
                            that.editing = true;
                            that.dialogVisible = true;
                        });
                    }
                });
            },
            handleDetail(row) {
                let that = this;

                that.$http.get(`${urls.coursewareFind}/${row.id}`).then((res) => {
                    // console.log(res.data.data);
                    if(res.data.status === 200) {
                        that.form = {
                            ...res.data.data,
                            textFileList: [{name: res.data.data.textName, url: res.data.data.textUrl}],
                            videoFileList: [{name: res.data.data.videoName, url: res.data.data.videoUrl}],
                        };
                        that.$nextTick(function() {
                            this.editingType = this.editingButtons[1];
                            that.editing = false;
                            that.dialogVisible = true;
                        });
                    }
                });
            },
            release() {
                // console.log('release');
                this.editing = false;
                this.editingType = this.editingButtons[0];
                this.dialogVisible = true;
            },
            dlOnClosedClearData: function() {
                this.form= {
                    id: '',
                    name: '',
                    description: '',
                    textFileList: [],
                    videoFileList: [],
                    sort: 99
                };
            },
            filePreview(previewType) { // 文件预览
                let that = this;

                let url = '', nameArr = [];
                switch (previewType) {
                    case 'text':
                        url = `${urls.coursewarePreviewText}${that.form.id}`;
                        nameArr = that.form.textName.split('.');
                        break;
                    case 'video':
                        url = `${urls.coursewarePreviewVideo}${that.form.id}`;
                        nameArr = that.form.videoName.split('.');
                        break;
                }
                let ext = nameArr.splice(-1, 1)[0];

                that.$http({
                    url,
                    method: 'get',
                    responseType: 'blob',
                }).then((res) => {
                    man.file.blobAsFile(res, nameArr.join(), ext, false);
                });
            },
            handleCoursewareRemove(file, fileList) {
                console.log('handleCoursewareRemove', file, fileList)
            },
            handleVideoRemove(file, fileList) {
                console.log('handleVideoRemove', file, fileList)
            },
            handleCoursewareChange(file, fileList) { // 课件上传
                console.log('handleCoursewareChange', file);
                const fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase() ;
                if (fileType !== 'pdf' && fileType !== 'doc' && fileType !== 'docx' && fileType !== 'jpg' && fileType !== 'png' && fileType !== 'ppt' && fileType !== 'pptx') {
                    this.$message.warning('请上传合适的文件')
                    fileList.splice(fileList.length - 1, 1);
                    this.form.textFileList = fileList;
                    return false
                }
                if (file.size > 20 * 1000 * 1000) {
                    this.$message.warning('文件过大，请压缩后重新上传')
                    fileList.splice(fileList.length - 1, 1);
                    this.form.textFileList = fileList;
                    return false
                }
                this.fileUpload(file, fileList, 'TEXT');
            },
            handleVideoChange(file, fileList) { // 视频上传
                // console.log('handleVideoChange', file, fileList);

                const fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase() ;
                if (fileType !== 'mp4') {
                    this.$message.warning('附件视频仅支持MP4格式，大小200M以内，建议使用短视频。')
                    fileList.splice(fileList.length - 1, 1);
                    this.form.videoFileList = fileList;
                    return false
                }
                if (file.size > 200 * 1024 * 1024) {
                    this.$message.warning('附件视频仅支持MP4格式，大小200M以内，建议使用短视频。')
                    fileList.splice(fileList.length - 1, 1);
                    this.form.videoFileList = fileList;
                    return false
                }
                this.uploadDialogVisible = true;

                this.fileUpload(file, fileList, 'VIDEO');
            },
            fileUpload(file, fileList, type) {
                // console.log(file)
                let formData = new FormData();
                formData.append('file', file.raw);
                
                this.$http({
                    url: urls.fileCoursewareUpload,
                    data: formData,
                    method: 'post',
                    headers: {'Content-Type': 'multipart/form-data'},
                }).then((res) => {
                    // console.log(res)
                    this.uploadDialogVisible = false;
                    if (res.data.status === 200) {
                        this.$message.success("上传成功!~");
                        switch(type) {
                            case 'TEXT':
                                this.form.textUrl = res.data.data;
                                this.form.textName = file.name;
                                this.form.textFileList = fileList.length > 1 ? fileList.splice(1, 1) : fileList;
                                break;
                            case 'VIDEO':
                                this.form.videoUrl = res.data.data;
                                this.form.videoName = file.name;
                                this.form.videoFileList = fileList.length > 1 ? fileList.splice(1, 1) : fileList;
                                break;
                            default:
                                break;
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                }).catch(err => {
                    console.log(err)
                    this.uploadDialogVisible = false;
                    this.$message.error(err.message);
                })
            },

            removeCourseware: function(id) {
                let that = this;

                that.$confirm('是否删除该课件', '确认信息', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确认删除',
                    cancelButtonText: '取消'
                }).then(() => {
                    that.$http.post(`${urls.userServer.coursewareFrontDel}/${id}`).then((res) => {
                        if(res.data.status === 200) {
                            that.$message.success('删除课件成功');
                            that.getCoursewareList();
                        } else {
                            that.$message.error('删除课件失败');
                        }
                    });
                }).catch();
            },

            /*downloadPreview: function(previewType) {
                let that = this;

                let url = '', name = '', nameArr = [];
                switch (previewType) {
                    case 'text':
                        name = that.form.textFileList[0].name;
                        url = `${urls.userServer.coursewarePreviewFrontText}/${name}`;
                        nameArr = that.form.textName.split('.');
                        break;
                    case 'video':
                        name = that.form.videoFileList[0].name;
                        url = `${urls.userServer.coursewarePreviewFrontVideo}/${name}`;
                        nameArr = that.form.videoName.split('.');
                        break;
                }
                let ext = nameArr.splice(-1, 1)[0];

                that.$http({
                    url,
                    method: 'get',
                    responseType: 'blob',
                }).then((res) => {
                    man.file.blobAsFile(res, nameArr.join(), ext, false);
                });
            },*/
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #course-management {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            >.search-bar {
                margin: 1vw 0 1vw 2vw;
                width: 90%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.73vw;
                .lf {
                    display: flex;
                    align-items: center;
                    .course-entrybox,
                    .courseware-entrybox {
                        background-color: #fff;
                        margin-right: 10px;
                        line-height: 40px;
                        border-radius: 4vw;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #666;
                        /*.icon-search {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background: url("../../assets/images/user-center/icon_search_dark.png") no-repeat center center;
                            background-size: 100%;
                            margin-left: 10px;
                        }
                        span {
                            display: inline-block;
                            margin-left: 10px;
                            padding-right: 10px;
                            border-right: 1px solid #eaeaea;
                        }*/
                        input {
                            width: 12vw;
                            padding: 0 15px 0 10px;
                            border: 1px solid #ddd;
                            border-radius: 4vw;
                        }
                    }
                    .course-status-options {
                        width: 10vw;
                    }
                    .calendar {
                        margin: 0 10px;
                        @{deep} .el-date-editor {
                            border: 0;
                            border-radius: 100px;
                            width: 240px;
                        }
                    }
                    .btn-search {
                        background-color: #24CEFF;
                        color: #fff;
                        line-height: 40px;
                        padding: 0 1.5vw;
                        cursor: pointer;
                        border-radius: 100px;
                        transition: all .3s linear;
                        &:hover {
                            background-color: #129DC6;
                        }
                    }
                }
                .rf {
                    background: #1AAB7D;
                    color: #fff;
                    line-height: 40px;
                    padding: 0 2vw;
                    border-radius: 4vw;
                    cursor: pointer;
                    transition: all .3s linear;
                    &:hover {
                        background-color: #157C5D;
                        box-shadow: 0 2px 5px 1px rgba(60,60,60,.5);
                    }
                }
            }
            .table {
                width: 90%;
                margin: 1vw 0 1vw 2vw;
                @{deep} .el-table {
                    overflow: visible;
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;
                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(7) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                    .el-table__body-wrapper {
                        overflow: visible;
                        tbody {
                            .el-table__row:last-child {
                                border-radius: 0 0 10px 10px;
                                border-bottom: 0;
                                td:first-child {
                                    border-radius: 0 0 0 10px;
                                }
                                td:last-child {
                                    border-radius: 0 0 10px 0;
                                }
                            }
                            .el-table__row {
                                .cell {
                                    position: relative;
                                    overflow: visible;
                                    .off-shelf {
                                        cursor: pointer;
                                        text-decoration: underline;
                                    }
                                    .off-shelf-reason {
                                        display: none;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        background: #fff;
                                        padding: 6px;
                                        border-radius: 4px;
                                        box-shadow: 0 0 5px 1px #aaa;
                                        width: 200px;
                                        text-align: left;
                                        z-index: 999;
                                    }
                                    &:hover {
                                        .off-shelf-reason {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
            .dialog {
                @{deep} .dialog-footer {
                    text-align: center;
                }
                .courseware {
                    .course {
                        color: #333;
                        span {
                            color: #777;
                        }
                    }
                    .courseware-form {
                        padding: 1vw;
                        border-radius: 4px;
                        border: 1px solid #ddd;
                        margin-top: 1vw;
                        .search-bar {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            >span {
                                display: inline-block;
                                min-width: 4em;
                                margin-right: 10px;
                                font-size: 0.83vw;
                                color: #333;
                                font-weight: bold;
                            }
                            .el-input{
                                margin-right: 1vw;
                            }
                            .el-select {
                                margin-right: 4vw;
                            }
                        }
                        .courseware-pagination {
                            margin-top: 1vw;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        @{deep} .el-table {
                            margin-top: 1vw;
                            th {
                                background-color: #409EFF;
                                color: #fff;
                            }
                            .el-checkbox__input.is-checked .el-checkbox__inner {
                                background-color: #fff;
                                &::after {
                                    border-color: #409EFF;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-top: 1.5vw;
                        text-align: center;
                    }
                }
                .course-detail {
                    >ul {
                        >li {
                            margin-bottom: 20px;
                            color: #606060;
                            label {
                                float: left;
                                min-width: 6em;
                            }
                            div {
                                float: left;
                                width: calc(100% - 6em);
                                position: relative;
                                box-sizing: border-box;
                                >span:first-child {
                                    display: inline-block;
                                    width: 100%;
                                    padding-right: 3em;
                                    box-sizing: border-box;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                    white-space: nowrap;
                                }
                                .btn-preview {
                                    position: absolute;
                                    right: 0;
                                    margin-left: 10px;
                                    color: #409EFF;
                                    cursor: pointer;
                                }
                            }
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }
                        }
                    }
                }
            }
        }
    }

    #js_remark{
        display: none;
    }
    .upload-dialog {
        @{deep} .el-dialog.is-fullscreen {
            background-color: rgba(0, 0, 0, .3);
        }
    }
    .upload-dialog-text {
        text-align: center;
        color: #fff;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
    }
    .sk-chase {
        width: 50px;
        height: 50px;
        position: relative;
        animation: sk-chase 2.5s infinite linear both;
        margin: 30vh auto 0;
    }

    .sk-chase-dot {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        animation: sk-chase-dot 2.0s infinite ease-in-out both;
    }

    .sk-chase-dot:before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 100%;
        animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
    }

    .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
    .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
    .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
    .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
    .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
    .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
    .sk-chase-dot:nth-child(1):before { background-color: lightskyblue; animation-delay: -1.1s; }
    .sk-chase-dot:nth-child(2):before { background-color: lightblue; animation-delay: -1.0s; }
    .sk-chase-dot:nth-child(3):before { background-color: lightgreen; animation-delay: -0.9s; }
    .sk-chase-dot:nth-child(4):before { background-color: lightyellow; animation-delay: -0.8s; }
    .sk-chase-dot:nth-child(5):before { background-color: lightsalmon; animation-delay: -0.7s; }
    .sk-chase-dot:nth-child(6):before { background-color: lightcoral; animation-delay: -0.6s; }
    
    @keyframes sk-chase {
        100% { transform: rotate(360deg); }
    }

    @keyframes sk-chase-dot {
        80%, 100% { transform: rotate(360deg); }
    }

    @keyframes sk-chase-dot-before {
        50% {
            transform: scale(0.4);
        } 100%, 0% {
              transform: scale(1.0);
          }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #course-management{
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    background: #fff;
                    width: 94.7%;
                    margin:0 auto;
                    .fontFn(10,border-top-left-radius);
                    .fontFn(10,border-top-right-radius);
                    padding: 0 30px;
                    color:#34254C;
                    font-weight: bold;
                    .fontFn(18);
                    .fontFn(48,height);
                    .fontFn(48,line-height);
                }
                &>.search-bar{
                    background: #fff;
                    flex-direction: column;
                    .fontFn(10,border-bottom-left-radius);
                    .fontFn(10,border-bottom-right-radius);
                    width: 94.7%;
                    margin:0 auto;
                    padding:0 26px 3%;
                    margin-bottom: 5%;
                    box-shadow: 0px 5px 10px #D5F5FF;
                    .lf{
                        flex-direction: column;
                        width: 100%;
                        .course-entrybox,
                        .courseware-entrybox{
                            .fontFn(20,border-radius);
                            width: 100%;
                            background: #F3F3F3;
                            margin-right: 0;
                            margin-bottom: 5%;
                            .fontFn(38,line-height);
                            .icon-search{
                                .fontFn(18,width);
                                .fontFn(18,height);
                                margin-left: 16px;
                            }
                            span{
                                color:#555859;
                                .fontFn(14);
                                .fontFn(26,line-height);
                            }
                            input{
                                width: auto;
                                flex-grow:1;
                                .fontFn(14);
                                border:none;
                                padding-left: 18px;
                            }
                        }
                        .course-status-options{
                            width: 100%;
                            margin-bottom: 5%;

                            @{deep} .el-select{
                                width: 100%;
                            }

                            @{deep} .el-input__inner{
                                padding: 0 32px 0 16px;
                                .fontFn(14);
                                .fontFn(20,border-radius);
                                border:none;
                                background: #F3F3F3;
                                .fontFn(38,height);
                                .fontFn(38,line-height);
                            }
                        }
                        .calendar{
                            width: 100%;
                            margin:0 auto 5%;
                            .el-date-editor{
                                padding: 0;
                                width: 100%;
                                background: #F3F3F3;
                                .fontFn(20,border-radius);
                                @{deep} .el-range__icon{
                                    .fontFn(18);
                                    height: 32px;
                                    margin-left: 16px;
                                }
                                @{deep} .el-range-separator{
                                    height: 10px;
                                    line-height: 10px;
                                }
                                @{deep} .el-range-input{
                                    .fontFn(38,line-height);
                                    .fontFn(14);
                                }
                            }
                        }
                        .btn-search{
                            width: 100%;
                            text-align: center;
                            .fontFn(14);
                            .fontFn(38,height);
                            .fontFn(38,line-height);
                            margin-bottom: 5%;
                        }
                    }
                    .rf{
                        width: 100%;
                        text-align: center;
                        .fontFn(14);
                        .fontFn(38,height);
                        .fontFn(38,line-height);
                        margin-bottom: 5%;
                    }
                }

                .table{
                    .el-table{
                        display: none;
                    }
                    width:94.7%;
                    margin:0 auto;
                }
            }
        }

        #js_remark{
            display: list-item;
        }
    }
</style>

<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
            margin-top: 8% !important;
        }

        .vue-cropper{
            width: 100% !important;
        }

        .cropper-crop-box{
            transform: translate3d(0,0,0) !important;
        }
    }

    .el-message-box{
        width: 96%;
    }

    .popDateBox{
        width: auto !important;
        top:2% !important;
        left:2% !important;
        right:2% !important;
        margin:0 !important;
        background:rgba(0,0,0,0.5);

        .popper__arrow{
            display: none;
        }

        .el-picker-panel__body{

            min-width: auto !important;
            background: #fff !important;
        }

        .el-date-range-picker__content{
            float:none !important;
            width: 100% !important;
        }
    }
}
</style>